$(document).ready(function() {

	setTextForTabControl()
	setProjectFilterUrl()
	let zero = 0
	$(window).on('scroll', function () {
		$('header').toggleClass('scrolling', $(window).scrollTop() > zero)
	})
	$('.service-zone-list').css({
		paddingLeft: $('.uk-container-large').offset().left + 15
	})
	$('.home-5 .project-filter').css({
		paddingLeft: $('.uk-container-large').offset().left + 15
	})

	$(window).resize(function () { 
		$('.service-zone-list').css({
			paddingLeft: $('.uk-container-large').offset().left + 15
		})
		$('.home-5 .project-filter').css({
			paddingLeft: $('.uk-container-large').offset().left + 15
		})
	});

	$('.recruitment-2 .read-more').click(function(e){
		e.preventDefault()
		$(this).prev().toggleClass('expend')
	})


	$(".project-img-slider").each(function(index){
		var $this = $(this);
		$this.addClass("project-img-slider-" + index);
		$this.find(".swiper-prev").addClass("btn-prev-" + index);
		$this.find(".swiper-next").addClass("btn-next-" + index);

		var projectThumbnailSwiper = new Swiper(".project-img-slider-" + index + " .thumb-slider .swiper-container", {
			slidesPerView: 5,
			spaceBetween: 1,
			breakpoints: {
				576: {
					slidesPerView: 4
				}
			}
		});

		var projectImageSwiper = new Swiper(".project-img-slider-" + index + " .img-slider .swiper-container", {
			navigation: {
				prevEl: ".btn-prev-" + index,
				nextEl: ".btn-next-" + index
			},
			thumbs: {
				swiper: projectThumbnailSwiper
			}
		});
	});

	$('.recruit-detail .btn-apply').click(function(e) {
		e.preventDefault();
		$('.apply-form').slideToggle()
	})

	$('.service-slider video').each(function(){
		var _this = $(this)
		if( _this.paused) {
			_this.next().fadeIn()
			console.log('paused');
			
		} else {
			_this.next().fadeOut()
			console.log('playing');
			
		}
	})

	if($(window).width() < 1025) {
		$('.about-sidenav .sidenav-title').click(function(e){
			$('.about-sidenav .sidenav-links').slideToggle()
		})
	}


	// var wowHeight;
	// if ($(window).width() >= 1368) {
	// 	wowHeight = 800;
	// }
	// if ($(window).width() < 1367.98) {
	// 	wowHeight = 800;
	// }

	$("#wowbook").wowBook({
		width: 1240,
		height: 800,
		centeredWhenClosed: true,
		hardcovers: true,
		pageNumbers: false,
		mouseWheel: true,
		controls: {
			zoomIn: "#zoomin",
			zoomOut: "#zoomout",
			next: "#next",
			back: "#back",
			first: "#first",
			last: "#last"
		},
		scaleToFit: ".wowbook-wrapper"
	});
	
});

const bottomMenuMobileMapping = new MappingListener({
	selector: ".bottom-menu",
	mobileWrapper: ".mobile-menu",
	mobileMethod: "appendTo",
	desktopWrapper: ".bottom-right-header",
	desktopMethod: "prependTo",
	breakpoint: 1025
  }).watch();

const topMenuMobileMapping = new MappingListener({
	selector: ".top-menu",
	mobileWrapper: ".mobile-menu",
	mobileMethod: "appendTo",
	desktopWrapper: ".top-right-header",
	desktopMethod: "prependTo",
	breakpoint: 1025
  }).watch();




function setTextForTabControl() {
	var currentTab = $('.news-switcher .uk-active')
	var nextTabText = currentTab.next().text()
	var lastTabText = $('.news-switcher li').last().text()
	var prevTab = $('.tab-home-news .uk-active .previous-tab span')
	var nextTab = $('.tab-home-news .uk-active .next-tab span')
	prevTab.text(lastTabText)
	nextTab.text(nextTabText)
	
	UIkit.util.on('.tab-home-news', 'show', function () {
		var currentTab = $('.news-switcher .uk-active')
		var firstTabText = $('.news-switcher li').first().text()
		var lastTabText = $('.news-switcher li').last().text()
		var prevTabText = currentTab.prev().text()
		var nextTabText = currentTab.next().text()
		var prevTab = $('.tab-home-news .uk-active .previous-tab span')
		var nextTab = $('.tab-home-news .uk-active .next-tab span')
		
		if(prevTabText !== '') {
			prevTab.text(prevTabText)
		} else {
			prevTab.text(lastTabText)
		}

		if(nextTabText !== '') {
			nextTab.text(nextTabText)
		} else {
			nextTab.text(firstTabText)
		}
	})
}

function setProjectFilterUrl(){
	var projectUrl = ''
	var selectedCatalogue = $("#projectCatalogue"). children("option:selected").val()
	var selectedStatus = $("#projectStatus"). children("option:selected").val()
	try {
		var suffixInitUrl = $("#projectCatalogue"). children("option:selected").val().slice(1)
	} catch (error) {
	}
	$("#projectCatalogue").on('change', function(){
		selectedCatalogue = $(this). children("option:selected").val()
		try {
			suffixInitUrl = selectedCatalogue.slice(1)
		} catch (error) {}
	})

	$("#projectStatus").on('change', function(){
		selectedStatus = $(this). children("option:selected").val()
	})

	$('.btn-filter').click(function(e){
		e.preventDefault()
		projectUrl = selectedCatalogue + selectedStatus + '-' + suffixInitUrl
		window.location.assign(projectUrl)
		
	})
}

var projectDetailThumbSlider = new Swiper('.project-detail-wrapper .thumb-slider .swiper-container', {
	slidesPerView: 5,
	spaceBetween: 30,
	slideToClickedSlide: true,
	navigation: {
		prevEl: '.project-detail-wrapper .thumb-slider .swiper-prev',
		nextEl: '.project-detail-wrapper .thumb-slider .swiper-next'
	},
	breakpoints: {
		1025: {
			slidesPerView: 4
		},
		768: {
			slidesPerView: 3
		},
		576: {
			slidesPerView: 3,
			spaceBetween: 10
		}
	}
})

var projectDetailImageSlider = new Swiper('.project-detail-wrapper .img-slider .swiper-container', {
	thumbs: {
		swiper: projectDetailThumbSlider
	}
})


var clientSlider = new Swiper('.about-5 .list-client .swiper-container', {
	slidesPerView: 4,
	slidesPerColumn: 3,
	spaceBetween: 30,
	navigation: {
		prevEl: ".about-5 .list-client .swiper-prev",
		nextEl: ".about-5 .list-client .swiper-next"
	},
	breakpoints: {
		768: {
			slidesPerView: 3
		},
		576: {
			slidesPerView: 2
		}
	}
})